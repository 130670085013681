import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import slugify from '../slugify';

const ComediansPage = ({ location }) => {
  const { allSanityComedian } = useStaticQuery(graphql`
    query {
      allSanityComedian(sort: { fields: _createdAt, order: ASC }) {
        nodes {
          image {
            ...ImageWithPreview
          }
          name
          hidden
        }
      }
    }
  `);

  const comedians = allSanityComedian.nodes;

  return (
    <Layout location={location}>
      <SEO title="Home" />
      <div className={'comedians flex flex-wrap justify-around'}>
        {comedians
          .filter(comedian => comedian.name != 'TBC' && comedian.name != 'No Compere' && !comedian.hidden)
          .map((comedian, index) => (
            <Link className={'comedian'} to={`/comedians/${slugify(comedian.name)}`} key={index}>
              <Image
                className={'max-w-full mr-4 my-4 w-64 shadow-md border border-gray-800'}
                {...comedian.image}
                alt={`Image of ${comedian.name}`}
              />
              <span className={'block text-center my-4 text-xs uppercase'}>{comedian.name}</span>
            </Link>
          ))}
      </div>
    </Layout>
  );
};

export default ComediansPage;
